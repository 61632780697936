import React, { useState, useEffect, useRef, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInterval, useMount } from 'react-use'
import ReactPlayer from 'react-player'

import { PlayArrow, AccessTime, VolumeUp, VolumeOff } from '@material-ui/icons';

import { bgImage, colours, type } from '../../styles/global'
import { media, useBreakpoint, isClient } from '../../styles/utils'
import { formatDate, msToTime } from '../../utils'
import { usePlayer, playerContext } from './utils'

const Player = (props) => {
    const [renderAudio, audio] = usePlayer()
    const { status, setStatus, countdown, setCountdown } = useContext(playerContext)

    const resolveIconStatus = () => {
        const statusIcons = {
            begin: <PlayArrow/>,
            wait: <AccessTime/>,
            starting: <AccessTime/>,
            playing: <VolumeUp/>,
            muted: <VolumeOff/>
        }

        return statusIcons[status]
    }

    const handleToggle = () => {
        if (status == 'begin') audio.play()
        if (status == 'playing') audio.toggleMute()
        if (status == 'muted') audio.toggleMute()
    }

    const renderControl = () => {
        if (status == 'finished') return;

        return (
            <Control>
                <Toggle
                    onClick={handleToggle}
                >
                    {resolveIconStatus()}
                </Toggle>
            </Control>
        )
    }

    const renderStatusText = () => {
        let statusText;

        if (status == 'begin') return (
            <Heading onClick={handleToggle}>
                Press to begin
            </Heading>
        )

        if (status == 'wait') return (
            <Heading onClick={handleToggle}>
                Please Wait...
            </Heading>
        )

        if (status == 'starting') return (
            <Heading>
                Starting in
                <span>{countdown}</span> 
            </Heading>
        )

        if (status == 'playing') return (
            <Subheading onClick={handleToggle}>
                Press to mute
            </Subheading>
        )

        if (status == 'muted') return (
            <Subheading onClick={handleToggle}>
                Audio muted
            </Subheading>
        )

        if (status == 'finished') return (
            <Heading>
                This event is no longer available.<br/>
                Thanks for listening
            </Heading>
        )
    }

	return (
        <Wrapper>
            {renderControl()}
            {renderAudio()}

            <Status
                status={status}
            >
                {renderStatusText()}
            </Status>
        </Wrapper>
	)
}

// Shared

const Heading = styled.h3``
const Subheading = styled.h4``
const Description = styled.div``
const Info = styled.div``

const Image = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`
// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 100%;
    margin-top: 48px;
`

// Control

const Control = styled.div`
    width: 317px;
    height: 317px;
    border-radius: 50%;
    border: 6px solid #272727;
    background:  linear-gradient(179.77deg, #F80093 0%, rgba(255, 255, 255, 0) 99.79%), url(${require('../../assets/images/player-bg.png')});

    display: flex;
    justify-content: center;
    align-items: center;
`

const Toggle = styled.div`
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background: linear-gradient(180deg, #FFFFFF 10.94%, #F2F2F2 90.1%), rgba(255, 255, 255, 0.96);
    border: 5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        color: black;
        width: 64px;
        height: 64px;
    }
`

// Status

const Status = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    height: 55px;

    > * {
        color: white;
        font-weight: 600;
    }

    ${Heading} {
        font-size: 19px;
        line-height: 26px;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        span {
            color: ${colours.pink}
        }
    }

    ${Subheading} {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.045em;
        margin-top: 10px;
    }

    /* Finished text */

    ${props => {
        if (props.status == 'finished') return css`
            margin-bottom: 100px;
            margin-top: 80px;
            height: auto;
        `
    }}
    
`





export default Player