import React, { useState } from 'react'
import { navigate, Link } from 'gatsby'
import { useWindowScroll } from 'react-use';
import styled from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'

const Footer = (props) => {
	return (
		<Wrapper>
			<Container>
				<LogoLive/>
				<LogoRow/>
			</Container>
		</Wrapper>	
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	width: 100%;
	
	background: linear-gradient(360deg, rgba(0,0,0, 0.3) -40%, rgba(255, 255, 255, 0) 87.73%), url(${require('../../assets/images/footer-bg.png')});
	background-blend-mode: multiply, normal;

	padding-top: 43px;
	padding-bottom: 55px;
	box-sizing: border-box;
	${padding};

	height: 450px;

	${media.phone`
		height: 430px;
		padding-top: 40px;
		padding-bottom: 36px;
	`}
`

const Container = styled.div`
    ${container}
    flex-direction: column;
	align-items: flex-start;
	height: 100%;
	position: relative;
`

// Logos

const LogoLive = styled.div`
	${bgIcon}
	width: 88px;
	height: 40px;
	background-image: url(${require('../../assets/images/footer-logo-top.svg')});

	position: absolute;
	right: 0;
	top: 0;
`

const LogoRow = styled.div`
	${bgIcon}
	width: 300px;
	height: 94px;
	background-image: url(${require('../../assets/images/footer-logos-bottom.svg')});

	position: absolute;
	right: 0;
	bottom: 0;
`

export default Footer
